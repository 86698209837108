import React from 'react';

const UnderMaintenance = () => {
  return (
    <div className="container text-center my-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-sm-10">
          <img
            src={process.env.REACT_APP_PUBLIC_URL + "/Maintenance.jpg"}
            alt="Under Maintenance"
            className="img-fluid mb-4"
          />
          <h2 className="mb-3">We're Under Maintenance</h2>
          <p className="mb-4">
            Our site is temporarily down for scheduled maintenance. We apologize for any inconvenience.
          </p>
          <div className="alert alert-warning p-3">
            <strong>
              असुविधा के लिए खेद है, हमारे पेमेंट गेटवे पार्टनर की तकनीकी खामियों की वजह से डिपॉजिट और विड्रवाल मैं समस्या आ रही है। कृपया सभी प्लेयर्स से अनुरोध है की धैर्य बनाए रखे, आपका अमाउंट सेफ है और समस्या के निवारण में 48 से 72 घंटे लग सकते हैं।
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
}; 

export default UnderMaintenance;
