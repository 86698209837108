import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Homepage from './uiComponents/Homepage';
import Landing from './uiComponents/Landing';
import userLogin from './uiComponents/Login';
import Mywallet from './uiComponents/Mywallet';
import Addcase from './uiComponents/Addcase';
import Withdrawopt from './uiComponents/Withdrawopt';
import Profile1 from './uiComponents/Profile1';
import ViewGame1 from './uiComponents/ViewGame1';
import Gamehistory from './uiComponents/Gamehistory';
import 'animate.css';
import axios from 'axios';
import Transactionhistory from './uiComponents/Transactionhistory';
import Referralhis from './uiComponents/Referralhis';
import Refer from './uiComponents/Refer';
import Notification from './uiComponents/Notification';
import Support from './uiComponents/Support';
import Kyc2 from './uiComponents/Kyc2';
import RefundPolicy from './uiComponents/RefundPolicy';
import terms_condition from './uiComponents/terms_condition';
import PrivacyPolicy from './uiComponents/PrivacyPolicy';
import Gamerules from './uiComponents/Gamerules';
import ResponsibleGaming from './uiComponents/ResponsibleGaming';
import Return from './uiComponents/Return';
import Notify from './uiComponents/Notify';
import Header from './Components/Header';
import Rightcontainer from "./Components/Rightcontainer";
import Redeem from "./uiComponents/Redeem";
import AboutUs from './uiComponents/AboutUs';
import { decrypt } from './utils/cryptoUtils';
import UnderMaintenance from './UnderMaintenance';  // Import the maintenance page

const App2 = () => {
        const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
        const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
        const nodeMode = process.env.NODE_ENV;
        let baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

        const access_token = localStorage.getItem("token");
        const [user, setUser] = useState();
        const [isMaintenance, setIsMaintenance] = useState(false); // Maintenance flag state


        const getUser = () => {
                const headers = { Authorization: `Bearer ${access_token}` };
                try {
                        axios.get(baseUrl + `me`, { headers })
                                .then((res) => {
                                        const decryptData = decrypt(res.data.data);
                                        setUser(decryptData);
                                }).catch((e) => {
                                        if (e.response && e.response.status === 401) {
                                                localStorage.removeItem("token");
                                        }
                                });
                } catch (error) {
                        console.log(error);
                }
        };

        const fetchData = async () => {
                try {
                        const response = await fetch(baseUrl + "settings/data");

                        // Check if the response is empty (status 204: No Content)
                        if (response.status === 204 || response.headers.get("content-length") === "0") {
                                throw new Error("No content in response");
                        }

                        // Check if the response is OK (status code 200-299)
                        if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                        }


                        // Try parsing the JSON
                        const data = await response.json();


                        // Now decrypt the data
                        const decryptData = decrypt(data.data); // Assuming the encrypted data is inside `data.data`
                        // console.log(decryptData.data);
                        setIsMaintenance(decryptData.data.UnderMaintenance)
                        // You can further handle the decrypted data here
                        // setWebsiteSettings(decryptData);
                } catch (error) {
                        console.log("Error fetching data:", error);
                }
        };

        useEffect(() => {
                getUser();
                fetchData();
                
               
                // eslint-disable-next-line
        }, []);

        // Render the maintenance page if site is under maintenance
        if (isMaintenance) {
                return <UnderMaintenance />;
        }

        // Normal rendering logic
        return (
                <>
                        <div className="leftContainer">
                                <div><Header user={user} /></div>
                        </div>

                        {!access_token ? (
                                <Switch>
                                        <Route exact path="/" component={Landing} />
                                        <Route path="/landing" component={Landing} />
                                        <Route path="/login" component={userLogin} />
                                        <Route path="/RefundPolicy" component={RefundPolicy} />
                                        <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
                                        <Route path="/term-condition" component={terms_condition} />
                                        <Route path="/about" component={AboutUs} />
                                        <Route path="/refund-policy" component={RefundPolicy} />
                                        <Route path="/contact-us" component={Support} />
                                        <Route path="/Gamerules" component={Gamerules} />
                                        <Route path="/responsible-gaming" component={ResponsibleGaming} />
                                        <Redirect to="/login" />
                                </Switch>
                        ) : (
                                <Switch>
                                        <Route path="/transaction-history" component={Transactionhistory} />
                                        <Route exact path="/Referral-history" component={Referralhis} />
                                        <Route exact path="/landing" component={Landing} />
                                        <Route exact path="/Gamehistory" component={Gamehistory} />
                                        <Route exact path="/HomePage/:Game" render={() => <Homepage walletUpdate={getUser} />} />
                                        <Route exact path="/refer" component={Refer} />
                                        <Route exact path="/Notification" component={Notification} />
                                        <Route exact path="/" component={Landing} />
                                        <Route path="/profile" component={Profile1} />
                                        <Route path="/viewgame1/:id" component={ViewGame1} />
                                        <Route path="/addcase" render={() => <Addcase walletUpdate={getUser} />} />
                                        <Route path="/Withdrawopt" render={() => <Withdrawopt walletUpdate={getUser} />} />
                                        <Route path="/wallet" component={Mywallet} />
                                        <Route path="/support" component={Support} />
                                        <Route exact path="/landing/:id" component={Landing} />
                                        <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
                                        <Route path="/Rules" component={Gamerules} />
                                        <Route path="/RefundPolicy" component={RefundPolicy} />
                                        <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
                                        <Route path="/term-condition" component={terms_condition} />
                                        <Route path="/return" render={() => <Return walletUpdate={getUser} />} />
                                        <Route path="/notify" component={Notify} />
                                        <Route path="/Redeem" render={() => <Redeem walletUpdate={getUser} />} />
                                        <Route path="/contact-us" component={Support} />
                                        <Route path="/refund-policy" component={RefundPolicy} />
                                        <Route path="/Gamerules" component={Gamerules} />
                                        <Route path="/responsible-gaming" component={ResponsibleGaming} />
                                        <Route path="/about" component={AboutUs} />
                                        <Redirect to="/landing" />
                                </Switch>
                        )}

                        <div className="rightContainer">
                                <Rightcontainer />
                        </div>
                </>
        );
};

export default App2;
