import React from 'react'
import { useState, useEffect } from 'react';
import { decrypt } from '../utils/cryptoUtils';

const Rightcontainer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");
  
      // Check if the response is empty (status 204: No Content)
      if (response.status === 204 || response.headers.get("content-length") === "0") {
        throw new Error("No content in response");
      }
  
      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Try parsing the JSON
      const data = await response.json();
  
      // Now decrypt the data
      const decryptData = decrypt(data.data); // Assuming the encrypted data is inside `data.data`
      // console.log(decryptData.data);
      setWebsiteSettings(decryptData.data);
  
      // You can further handle the decrypted data here
      // setWebsiteSettings(decryptData);
    } catch (error) {
      console.log("Error fetching data:", error);
      document.title = (WebSitesettings) ? WebSitesettings.WebTitle : '';
    }
  };
  


  document.title = (WebSitesettings) ? WebSitesettings.WebTitle : '';
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className='rightContainer'>
        <div className="rcBanner flex-center ">
          <picture className="rcBanner-img-container animate__bounce infinite ">
            <img src="/Images/LandingPage_img/Header_profile.jpg" alt="nothing" />
          </picture>
          <div className="rcBanner-text " style={{ fontWeight: 'bolder' }}>{(WebSitesettings) ? WebSitesettings.WebsiteName : ''} <span className="rcBanner-text-bold" style={{ fontWeight: 'normal' }}>Win Real Cash</span></div>
          <div className="rcBanner-footer">For best experience, open&nbsp;<a href="/" style={{
            color: 'rgb(44, 44, 44)',
            fontWeight: 500, textDecoration: 'none'
          }}>{(WebSitesettings) ? WebSitesettings.WebsiteName : ''}</a>&nbsp;on&nbsp;<img src={process.env.REACT_APP_PUBLIC_URL + '/Images/chrome.png'}
            alt="" />&nbsp;chrome mobile</div>
        </div>
      </div>
    </div>
  )
}

export default Rightcontainer