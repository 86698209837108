// src/utils/cryptoUtils.js
import CryptoJS from 'crypto-js';

// **WARNING:** Storing the key in the frontend is insecure.
// const SECRET_KEY = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY); // Ensure the same key is used as in the backend
const secretKey = process.env.REACT_APP_SECRET_KEY
// Encrypt data before sending to the server
export const encrypt = (data) => {
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return ciphertext;
};
// Decrypt data received from the server
export const decrypt = (cipherText) => {
  // Split the encrypted data into IV and encrypted text
  var bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
  return decryptedData;
};



